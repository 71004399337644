import {apiCall, paramsToString} from "utils/api";
import {sellerStoreId} from 'utils/auth';
import { toast } from "react-toastify";
import {NextPageContext} from "next";
import { COUNTRY_CODE } from "constants/store";

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/stores`;

export const getStoreProductSummary = async (storesPrimaryKey = 0, countryCode = COUNTRY_CODE.india) => {
  try {
    const response = await apiCall({
      method: 'get',
      headers: {
        'content-type': 'application/json'
      },
      url: `${HOST}/${sellerStoreId()}/storeProductSummary?${paramsToString({
        countryCode,
        storesPrimaryKey,
      })}`,
    })
    return response.data;
  } catch(err) {
    const res = err.response;
    toast.error(res?.data?.status);
  }
}

export const getBankDetailsMasked = async () => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/bankAccountDetails/web`,
  });

  return response.data;
};

export const getBankDetails = async () => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/bankAccountDetails`,
  });

  return response.data;
};

export const updateBankDetails = async (payload: any) => {
  const storeId = sellerStoreId();
  const data = {...payload, storeId};

  const response = await apiCall({
    method: 'put',
    url: `${HOST}/${storeId}/bankAccountDetails`,
    data,
  });

  return response.data;
};

export const getGSTDetails = async () => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/gstDetails`,
  });

  return response.data;
};

export const updateGSTDetails = async (data: any) => {
  const response = await apiCall({
    method: 'put',
    url: `${HOST}/${sellerStoreId()}/gstDetails`,
    data,
  });

  return response.data;
};

export const getFreshworksToken = async ({ctx}:{ctx: NextPageContext}) => {
  const response = await apiCall({
    ctx,
    method: 'get',
    url: `${HOST}/${sellerStoreId(ctx)}/freshdesk/clout/tokengeneration?${paramsToString(ctx.query)}`,
  });

  return response.data;
};

export const validateGstId = async (data: { gstId: string }) => {
  const response = await apiCall({
    method: 'post',
    url: `${HOST}/${sellerStoreId()}/validate/gstDetails`,
    data
  });

  return response.data;
};

export const getLatestTncContent = async ({
  storeType,
  entityType,
  tncPage,
}: {
  storeType: string;
  entityType: string;
  tncPage: string;
}, ctx?: NextPageContext) => {
  const res = await apiCall({
    ctx,
    method: 'get',
    url: `${HOST}/latestTnc?${paramsToString({
      storeType,
      entityType,
      tncPage,
     })}`,
  })

  return res.data;
};

export const acceptTnc = async (data: number[]) => {
  const response = await apiCall({
    method: 'post',
    url: `${HOST}/${sellerStoreId()}/acceptTnc`,
    data
  });

  return response.data;
};

export const checkTncStatus = async ({ entityType, tncPage }: { entityType: string; tncPage: string }) => {
  const response = await apiCall({
    method: 'get',
    url: `${HOST}/${sellerStoreId()}/tncStatus?${paramsToString({
      entityType,
      tncPage,
    })}`,
  });

  return response.data;
};
