import Image from "next/image";
import externalLinkIcon from 'assets/external-link-dark.svg';
import checkMarkIcon from "assets/check-mark.svg";
import checkMarkGrayIcon from "assets/check-mark-gray.svg";
import { acceptTnc, checkTncStatus } from "data/store";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { isStaff, isUserLoggedIn, userOnboardingStatus } from "utils/auth";
import dynamic from "next/dynamic";
import TooltipForNoAccess from "components/TooltipForNoAccess";
import { useRouter } from "next/router";
import { checkDevicePlatform } from "utils/misc";
import { PLATFORM_TYPE } from "constants/store";

const SecureModal = dynamic(() => import('../SecureModal'));

const TncUpdateAlert = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [tncStatusDetails, setTncStatusDetails] = useState({});
  const [isAccepting, setIsAccepting] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (typeof window === 'undefined' || router.pathname.startsWith('/tnc')) return;

    window.addEventListener('tncUpdateAlert', validateTncStatus);
    window.addEventListener('visibilitychange', handleWindowVisibilitychange);

    return () => {
      window.removeEventListener('tncUpdateAlert', validateTncStatus);
      window.removeEventListener(
        'visibilitychange',
        handleWindowVisibilitychange
      );
    };
  }, []);

  const handleWindowVisibilitychange = () => {
    if (typeof window !== 'undefined') {
      if (!document?.hidden) validateTncStatus();
    }
  };

  const handleAcceptClick = async () => {
    try {
      if (isStaff()) return;

      setIsAccepting(true);

      await acceptTnc(tncStatus(tncStatusDetails)?.tncIds);

      setIsAccepting(false);
      toast.success('Success!');
      handleClose();

      setTimeout(() => {
        window.location.reload();
      }, 400);
    } catch (err) {
      setIsAccepting(false);
      toast.error(err?.response?.data?.status)
    }
  }

  const validateTncStatus = useCallback(async () => {
    try {
      if (!isUserLoggedIn() || userOnboardingStatus()?.length) return;

      const tncStatusRes = await checkTncStatus({ entityType: 'dropshipper', tncPage: 'supplierDropshipperTnc' });

      if (!tncStatus(tncStatusRes)?.accepted) setTncStatusDetails(tncStatusRes);

      setIsVisible(!tncStatus(tncStatusRes)?.accepted);
    } catch (err) {
      console.log(err);
    }
  }, [isVisible]);

  const tncStatus = (tncStatus: any) => {
    const data: any = {
      tncUrls: [], 
      accepted: true, 
      tncIds: [],
      isBuyersAgreement: false,
      heading: '',
    };

    switch (tncStatus?.storeType) {
      case 'b2b':
        if (!tncStatus?.b2bTnc?.accepted) {
          data.accepted = tncStatus?.b2bTnc?.accepted;
          data.tncIds.push(tncStatus.b2bTnc.tncId);
          data.heading = 'Terms & Conditions';
          data.tncUrls.push({
            url: '/tnc/b2b',
            label: 'Review Terms & Conditions',
            type: 'b2b-tnc',
          });
        }

        if (!tncStatus?.b2bBuyersAgreement?.accepted) {
          data.accepted = tncStatus?.b2bBuyersAgreement?.accepted;
          data.tncIds.push(tncStatus.b2bBuyersAgreement.tncId);
          data.isBuyersAgreement = true;
          data.heading = data.heading ? `T&C and Buyer's Agreement` : `Buyer's Agreement`;
          data.tncUrls.push({
            url: '/tnc/b2b/buyers-agreement',
            label: `Review Buyer's Agreement`,
            type: 'b2b-buyersAgreement',
          });
        }

        break;
      case 'marketplace':
        data.accepted = tncStatus?.b2cTnc?.accepted;
        data.tncIds.push(tncStatus.b2cTnc.tncId);
        data.heading = 'Terms & Conditions';
        data.tncUrls.push({
          url: '/tnc',
          label: 'Review Terms & Conditions',
          type: 'b2c-tnc',
        });

        break;
    }

    return data;
  }

  const handleClose = () => {
    setIsVisible(false);
  }

  const handleNativeAppTncRedirection = (message: string) => {
    if (checkDevicePlatform(PLATFORM_TYPE.IOS)) {
      // @ts-ignore
      window?.webkit?.messageHandlers?.TncBuyerAgreementJavaScriptInterface?.postMessage(message);
    } else {
      // @ts-ignore
      window?.TncBuyerAgreementJavaScriptInterface?.[message]();
    }
  };

  const handleTncLinkClick = (tncInfo: any) => {
    // @ts-ignore
    if (window?.TncBuyerAgreementJavaScriptInterface || window?.webkit?.messageHandlers?.TncBuyerAgreementJavaScriptInterface) {
      switch (tncInfo.type) {
        case 'b2b-tnc':
          handleNativeAppTncRedirection('onReviewTncClick');
          break;
        case 'b2c-tnc':
          handleNativeAppTncRedirection('onReviewTncClick');
          break;
        case 'b2b-buyersAgreement':
          handleNativeAppTncRedirection('onReviewBuyersAgreementClick');
          break;
      }
    } else {
      window.open(tncInfo.url, '_blank');
    }
  }

  return (
    <>
      {isVisible && (
        <SecureModal 
          isVisible={isVisible} 
          onClose={handleClose}    
        >
          <div className="flex flex-col gap-[20px]">
            <div className="flex flex-col gap-[10px]">
              <span className="text-2xl font-bold">{`${tncStatus(tncStatusDetails)?.heading} Update`}</span>
              <div className="text-sm text-[#424242]">
                <p>{`We've updated our ${tncStatus(tncStatusDetails)?.heading} to serve you better.`}</p>
                <p>Please review and accept them to continue accessing the Roposo Clout.</p>
              </div>
            </div>

            <div className='flex flex-col gap-5'>
              {tncStatus(tncStatusDetails)?.tncUrls.map((tnc: any) => (
                <a
                  key={tnc.url}
                  className="text-sm font-bold flex items-center gap-1 cursor-pointer"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleTncLinkClick(tnc)}
                >
                  <span className="underline">{tnc.label}</span>
                  <div className="w-[16px] h-[16px]">
                    <Image
                      src={externalLinkIcon}
                      alt="externalLinkIcon"
                      height={16}
                      width={16}
                    />
                  </div>
                </a>
              ))}
            </div>

            {/* Divider */}
            <div className="border-t border-[#EDEDED]"></div>

            <div className="flex flex-col gap-[10px]">
              <div>
              <TooltipForNoAccess
                hasAccess={!isStaff()}
                position="top"
                message="Please ask admin to accept TnCs."
              >
                <button 
                  className={`flex items-center gap-[8px] text-sm font-bold text-[white] py-[10px] px-[22px] bg-[#0A0A0A] rounded ${isStaff() || isAccepting ? "bg-theme_1_grey0 text-theme_1_black border-[#e5e7eb] disabled:cursor-not-allowed" : ''}`}
                  onClick={handleAcceptClick}
                  disabled={isStaff() || isAccepting}
                >
                  <div className='flex gap-[8px] items-center justify-center rotate-6'>
                    <Image
                      alt={"checkIcon"}
                      src={isStaff() || isAccepting ? checkMarkGrayIcon : checkMarkIcon}
                      width={16}
                      height={16}
                    />
                  </div>
                  <span>Accept & Continue</span>
                </button>
              </TooltipForNoAccess>
              </div>
              <p className="text-xs text-[#757575]">By accepting, you acknowledge that you have read and agree to the updated terms.</p>
            </div>
          </div>
        </SecureModal>
      )}
    </>
  );
}

export default TncUpdateAlert;
